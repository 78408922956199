import {ReactComponent as Arrow} from 'assets/images/icons/arrow.svg';
import "./btn.scss";

const Btn = ({children, line, down, full, noIcon, blank = false, link, onClick}) => {
    const btnClass = (e) => {
        if (e) {
            return "btn btn--outline"
        }
        return "btn btn--main"
    }

    return (
        <>
            {link
                ?
                <a href={link} className={btnClass(line)} style={full ? {width: 100 + "%"} : {}} onClick={onClick}
                   target={blank ? '_blank' : ''}>
                    {children}
                    {down
                        ?
                        <Arrow className="arrow-down"/>
                        : !noIcon &&
                        <Arrow/>
                    }
                </a>
                :
                <button className={btnClass(line)} style={full ? {width: 100 + "%"} : {}} onClick={onClick}>
                    {children}
                    {down
                        ?
                        <Arrow className="arrow-down"/>
                        : !noIcon &&
                        <Arrow/>
                    }
                </button>

            }
        </>
    );
};

export default Btn;
