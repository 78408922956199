import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: []
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts: (state,payload) => {
            state.products = payload.payload;
        },
    },
})

export const { setProducts } = productsSlice.actions

export default productsSlice.reducer
