import axios from "axios";
import api from "../core/api";
import ContentModel from "./content.model";
import MediaContentModel from "./media-content.model";


export const getContents = () => {
    return axios.get(api.contents).then((res) => {
        let data = res.data.abouts?.items;
        if (data) {
            return data.map(i=>new ContentModel(i));
        }
    })
}

export const getMediaContents = () => {
    return axios.get(api.mediaContents).then((res) => {
        let data = res.data.content?.items;
        if (data) {
            return data.map(i=>new MediaContentModel(i));
        }
    })
}
