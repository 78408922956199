import ContactDiscoverComponent from "./components/contact-discover/contact-discover.component";
import ContactFormComponent from "./components/contact-form/contact-form.component";
import ContactMapComponent from "./components/contact-map/contact-map.component";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getContactsAction} from "./modules/contact.actions";

const ContactComponent = () => {
    const dispatch = useDispatch();
    const contact = useSelector((state) => state.contact.contact);

    useEffect(() => {
        dispatch(getContactsAction());
    }, [])
    return (
        <div className="contact">
            <ContactDiscoverComponent {...contact}/>
            <ContactFormComponent/>
            <ContactMapComponent/>
        </div>
    );
};

export default ContactComponent;
