export default class AppealModel {
    constructor(item) {
        this._setPhone(item);
        this._setAmount(item);
        this._setInterval(item);
        this._setPercentageRate(item);
        this._setMonthlyPayment(item);
        this._setPin(item);
        this._setApprovement(item);
        this._setStatics(item);

    }

    _setAmount({amount}) {
        this.amount = amount;
    }

    _setPhone({phone}) {
        this.phone = phone;
    }

    _setInterval({period}) {
        this.interval = period;
    }

    _setPercentageRate({percent}) {
        this.percentageRate = percent;
    }

    _setMonthlyPayment({result}) {
        this.monthlyPayment = Number(result);
    }

    _setPin({pin}) {
        this.pin = pin;
    }

    _setApprovement({approvement}) {
        this.approvement = approvement;
    }

    _setStatics() {
        this.amountTypeId = 1;
        this.intervalTypeId = 1;
    }


}
