import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    contact: {},
    topics: []
}

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        setContact: (state,payload) => {
            state.contact = payload.payload;
        },
        setTopics: (state,payload) => {
            state.topics = payload.payload;
        },
    },
})

export const { setContact,setTopics } = contactSlice.actions

export default contactSlice.reducer
