import HeaderComponent from "./components/layout/header/header.component";
import {Outlet, useLocation} from "react-router-dom";
import FooterComponent from "./components/layout/footer/footer.component";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getContentsAction, getMediaContentsAction} from "./modules/main.actions";
import LoaderComponent from "./components/layout/loader/loader.component";
import AnimatedOutlet from "./components/layout/animated-outlet";
import {AnimatePresence} from "framer-motion";
import {motion} from "framer-motion";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = [
    'color: #ffeb3b',
    'background: #474141',
    'font-size: 13px',
    'border: 1px solid #ffeb3b',
    'padding: 2px 5px',
    'border-radius: 5px'
].join(';');

function App() {
    const currentRoute = useLocation();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.main.loader);
    const location = useLocation();
    useEffect(() => {
        console.log(`%cversion: ${require('../package.json').version}`, styles);

        dispatch(getContentsAction());
        dispatch(getMediaContentsAction());
    }, []);
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [currentRoute])
    return (
        <div className="App">
            <HeaderComponent/>
            <AnimatePresence mode="popLayout">
                <motion.div
                    key={location.pathname}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <AnimatedOutlet/>
                </motion.div>
            </AnimatePresence>
            {/*<Outlet/>*/}
            <FooterComponent/>
            {loader && <LoaderComponent/>}
            <ToastContainer position="bottom-right"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable={false}
                            pauseOnHover
                            theme="colored"/>
        </div>
    );
}

export default App;
