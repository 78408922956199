import "./about-strategy-card.component.scss";

const AboutStrategyCardComponent = ({image, title, text}) => {

    return (
        <div className="col-lg-4 mb-40">
            <div className="about-strategy-card">
                <div className="about-strategy-card__image">
                    <img src={image}/>
                </div>
                <div className="about-strategy-card__title">
                    {title}
                </div>
                <div className="about-strategy-card__text"  dangerouslySetInnerHTML={{__html: text}}>
                </div>
            </div>
        </div>
    );
};

export default AboutStrategyCardComponent;
