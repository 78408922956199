export default class ContentModel {
    constructor(item) {
        this._setId(item);
        this._setDescription(item);
        this._setTitle(item);
        this._setHeading(item);
        this._setTypeId(item);
    }

    _setId({id}) {
        this.id = id;
    }

    _setTitle({title}) {
        this.title = title;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

    _setDescription({description}) {
        this.description = description;
    }

    _setTypeId({aboutType}) {
        this.typeId = aboutType?.id;
    }


}
