import HomeLoanInputComponent from "../home-loan-input/home-loan-input.component";
import "./home-loan-calc.component.scss";
import {useEffect, useState} from "react";
import Btn from "../../../../components/shared/button/btn";
import AppealModel from "../../modules/appeal.model";
import {useForm} from "react-hook-form";
import {postAppeal} from "../../modules/home.services";

const HomeLoanCalcComponent = () => {
    const [amountMin, amountMax, periodMin, periodMax, percentMin, percentMax] = [0, 500000, 0, 120, 0, 100];
    const [amountMeasure, periodMeasure, percentMeasure] = ["AZN", "Ay", "%"];

    const [amount, setAmount] = useState(500);
    const [period, setPeriod] = useState(3);
    const [percent, setPercent] = useState(1);
    const [result, setResult] = useState(0);

    const {register, handleSubmit, resetField, formState: {errors}} = useForm();

    const calcPMT = (percent, pv, nper) => {
        let rate = (percent / 100) / 12;
        let res = (Math.abs(rate * pv * Math.pow((1 + rate), nper) / (1 - Math.pow((1 + rate), nper))) || 0);
        return isFinite(res) ? res : 0
    };

    const amountChange = (val) => {
        if (!isNaN(val) && val <= amountMax && val >= amountMin) {
            setAmount(Number(val));
        }
    };
    const periodChange = (val) => {
        if (!isNaN(val) && val <= periodMax && val >= periodMin) {
            setPeriod(Number(val));
        }
    };
    const percentChange = (val) => {
        if (!isNaN(val) && val <= percentMax && val >= percentMin) {
            setPercent(Number(val));
        }
    };

    useEffect(() => {
        let res = calcPMT(percent, amount, period).toFixed(2);
        setResult(res)
    }, [amount, percent, period]);
    const sendAppeal = (data) => {
        let appealResult = new AppealModel({
            amount,
            period,
            percent,
            result,
            pin: data.pin,
            phone: data.phone,
            approvement: data.approvement
        });
        postAppeal(appealResult).then(res => {
            if (res) {
                resetField('pin');
                resetField('phone');
                resetField('approvement');
            }
        })

    }
    return (
        <div className="home-loan-calc">
            <div className="row">
                <div className="col-12">
                    <div className="home-loan-calc__inputs">
                        <HomeLoanInputComponent
                            label="Kreditin məbləği"
                            min={amountMin}
                            max={amountMax}
                            defaultNo={amount}
                            measure={amountMeasure}
                            onChange={amountChange}
                            steps={100}
                        />
                        <HomeLoanInputComponent
                            label="Kreditin müddəti"
                            min={periodMin}
                            max={periodMax}
                            defaultNo={period}
                            measure={periodMeasure}
                            onChange={periodChange}/>
                        <HomeLoanInputComponent
                            label="Faiz dərəcəsi"
                            min={percentMin}
                            max={percentMax}
                            defaultNo={percent}
                            measure={percentMeasure}
                            onChange={percentChange}/>
                    </div>
                </div>
                <div className={result > 1 ? "col-12" : "col-12 d-none"}>
                    <div className="home-loan-calc__result">
                        <div className="home-loan-calc__result-label">
                            Aylıq ödəniş
                        </div>
                        <div className="home-loan-calc__result-content">
                            {result} ₼
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row mb-20">
                        <div className="col-md-6 mb-20">
                            <label className="label">
                                FIN
                            </label>
                            <input type="text"
                                   className={errors.pin ? 'input error' : 'input'} {...register("pin", {required: true})}/>
                        </div>
                        <div className="col-md-6 mb-20">
                            <label className="label">
                                Əlaqə nömrəsi
                            </label>
                            <input type="text"
                                   className={errors.phone ? 'input error' : 'input'} {...register("phone", {required: true})}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {/*<div className="home-loan-calc__agreement">*/}
                            {/*    <input type="checkbox" id="approvement"*/}
                            {/*           className={errors.approvement ? 'checkbox error' : 'checkbox'} {...register("approvement", {required: true})}/>*/}
                            {/*    <label htmlFor="agreement" className="label">*/}
                            {/*        <strong><a href="#">AKB RAZILIQ</a></strong> vəriləsi*/}
                            {/*        üçün icazə*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-md-6">
                            <Btn full onClick={handleSubmit((data) => {
                                sendAppeal(data);
                            })}>
                                Müraciət et
                            </Btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeLoanCalcComponent;
