import {NavLink} from "react-router-dom";
import './menu.component.scss';
import paths from "core/paths";

const MenuComponent = () => {

    const menu = [
        {
            id: 1,
            name: 'Ana Səhifə',
            link: paths.home
        },
        {
            id: 2,
            name: 'Haqqımızda',
            link: paths.about
        },
        {
            id: 3,
            name: 'Məhsullar',
            link: paths.products
        },
        {
            id: 4,
            name: 'Partnyorlar',
            link: paths.partners
        },
        {
            id: 5,
            name: 'Əlaqə',
            link: paths.contact
        },
    ]

    return (
        <ul className="menu">
            {menu.map((i) =>
                (
                    <li key={i.id} className="menu__item">
                        <NavLink to={i.link}  className="menu__link" end>
                            {i.name}
                        </NavLink>
                    </li>
                )
            )}
        </ul>
    );
};

export default MenuComponent;
