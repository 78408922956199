import "./product-details-about.component.scss";
import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import image1 from "assets/images/icons/product-1.svg";
import image2 from "assets/images/icons/product-2.svg";
import image3 from "assets/images/icons/product-3.svg";
import image4 from "assets/images/icons/product-4.svg";
import image5 from "assets/images/icons/product-5.svg";
import ProductDetailsCard from "../product-details-card/product-details-card";

const ProductDetailsAboutComponent = ({heading,desc,period,amount,percent,commission,initialPayment}) => {
    const section = {
        title: 'Kredit Haqqında',
        desc: heading
    };
    const products = [
        {
            id: 1,
            image: image1,
            title: 'Kreditin məbləği  (AZN)',
            amount: amount
        }, {
            id: 2,
            image: image2,
            title: 'Kreditin ilkin ödənişi (%)',
            amount: initialPayment
        }, {
            id: 3,
            image: image3,
            title: 'Kreditin faiz dərəcəsi  (%)',
            amount: percent
        }, {
            id: 4,
            image: image4,
            title: 'Kreditin müddəti  (ay)',
            amount: period
        }, {
            id: 5,
            image: image5,
            title: 'Kreditin rəsmiləşdirilməsi üçün komissiya haqqı  (%)',
            amount: commission
        },
    ];
    return (
        <div className="product-details-about">
            <div className="container">
                <div className="row align-center mb-50">
                    <div className="col-lg-6">
                        <SectionHeading {...section}/>
                    </div>
                    <div className="col-lg-6">
                        <div className="product-details-about__text"  dangerouslySetInnerHTML={{__html: desc}}>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {products.map((item, index) => (
                        <ProductDetailsCard key={index} {...item}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductDetailsAboutComponent;
