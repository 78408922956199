import "./contact-map.component.scss";

const ContactMapComponent = () => {
    const locationUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97252.59399909426!2d49.78259545820314!3d40.3835107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dcb790779cf%3A0x4ccce5e2c105c554!2sX%C9%99tai%20Plaza%2C%20Cybernet!5e0!3m2!1sru!2s!4v1643471643729!5m2!1sru!2s';
    return (
        <section className="contact-map">
            <iframe
                src={locationUrl} allowFullScreen="" loading="lazy"></iframe>
        </section>
    )
        ;
};

export default ContactMapComponent;
