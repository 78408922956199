import "./footer-right.component.scss";
import locPhoto from 'assets/images/icons/loc.svg';
import emailPhoto from 'assets/images/icons/email.svg';
import phonePhoto from 'assets/images/icons/phone.svg';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getContactsAction} from "../../../../../views/contact/modules/contact.actions";
import {NavLink} from "react-router-dom";
import paths from "../../../../../core/paths";

const FooterRightComponent = () => {
    const dispatch = useDispatch();
    const contact = useSelector((state) => state.contact.contact);
    useEffect(() => {
        dispatch(getContactsAction());
    }, []);

    const site = [{
        url: paths.home, title: "Ana səhifə"
    }, {
        url: paths.about, title: "Haqqımızda"
    }, {
        url: paths.products, title: "Məhsullar"
    }, {
        url: paths.partners, title: "Partnyorlar"
    }, {
        url: paths.contact, title: "Əlaqə"
    },];

    const contacts = [{
        icon: emailPhoto, title: contact?.email
    }, {
        icon: phonePhoto, title: contact?.phone
    }, {
        icon: locPhoto, title: contact?.location
    },];
    return (<div className="footer-right">
        <div className="footer-right__content">
            <div className="footer-right__title">
                Qısayollar
            </div>
            <ul className="footer-right__list">
                {site.map((item,index) => (
                    <li className="footer-right__item" key={index}>
                        <NavLink to={item.url}  className="footer-right__link" end>
                            {item.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>

        <div className="footer-right__content">
            <div className="footer-right__title">
                Əlaqə
            </div>
            <ul className="footer-right__list">
                {contacts.map((item,index) => (
                    <li className="footer-right__item" key={index}>
                        <img src={item.icon} alt="icon" className="footer-right__icon"/>
                        {item.title}
                    </li>
                ))}
            </ul>
        </div>
    </div>);
};

export default FooterRightComponent;
