import "./partners-item.component.scss";

const PartnersItemComponent = ({id,logo, name, type, detailsModal}) => {
    return (<div className="col-lg-3 col-6">
        <div className="partners-item" onClick={()=>detailsModal(id)}>
            <div className="partners-item__head">
                <img src={logo} alt="partner" className="partners-item__logo"/>
                <span className="partners-item__name">{name}</span>
            </div>
            <div className="partners-item__type">
                {type}
            </div>
        </div>
    </div>);
};

export default PartnersItemComponent;
