import ProductsLeadComponent from "./components/products-lead/products-lead.component";
import ProductsListComponent from "./components/products-list/products-list.component";

const ProductsComponent = () => {
    return (
        <div>
            <ProductsLeadComponent/>
            <ProductsListComponent/>
        </div>
    );
};

export default ProductsComponent;
