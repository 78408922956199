import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/styles/index.scss";
import './index.scss';
import {RouterProvider} from "react-router-dom";
import router from "./core/routes";
import "core/interceptors";
import { Provider } from 'react-redux'
import {store} from "./core/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>
);

