import "./contact-images.component.scss";
import {useSelector} from "react-redux";

const ContactImagesComponent = () => {
    const image1 = useSelector((state) => state.main.contactPhoto1?.url);
    const image2 = useSelector((state) => state.main.contactPhoto2?.url);
    const image3 = useSelector((state) => state.main.contactPhoto3?.url);
    const image4 = useSelector((state) => state.main.contactPhoto4?.url);

    return (
        <div className="contact-images">
            <div className="contact-images__content contact-images__content--first">
                <div className="contact-images__item">
                    <img src={image1}/>
                </div>
                <div className="contact-images__item">
                    <img src={image2}/>
                </div>
            </div>
            <div className="contact-images__content contact-images__content--second">
                <div className="contact-images__item">
                    <img src={image3}/>
                </div>
                <div className="contact-images__item">
                    <img src={image4}/>
                </div>
            </div>
        </div>
    );
};

export default ContactImagesComponent;
