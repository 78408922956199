export default class MediaContentModel {
    constructor(item) {
        this._setId(item);
        this._setDescription(item);
        this._setTitle(item);
        this._setTypeId(item);
        this._setUrl(item);
        this._setHeading(item);
    }

    _setId({id}) {
        this.id = id;
    }

    _setTitle({title}) {
        this.title = title;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

    _setDescription({description}) {
        this.description = description;
    }
    _setUrl({fileUrl}) {
        this.url = fileUrl;
    }

    _setTypeId({contentType}) {
        this.typeId = contentType?.id;
    }
}
