import {configureStore} from '@reduxjs/toolkit'
import counterReducer from "../views/contact/modules/contact.store";
import mainReducer from "modules/main.store";
import productsReducer from "../views/products/modules/products.store";
import partnersReducer from "views/partners/modules/partners.store";
import aboutReducer from "views/about/modules/about.store";

export const store = configureStore({
    reducer: {
        contact: counterReducer,
        main: mainReducer,
        products: productsReducer,
        partners: partnersReducer,
        about: aboutReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
