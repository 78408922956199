import {getContacts, getTopics} from "./contact.services";
import {setContact, setTopics} from "./contact.store";
import axios from "axios";
import api from "../../../core/api";

export const getContactsAction = () => (dispatch) => {
    return getContacts()
        .then(res => {
            dispatch(setContact(res))
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.resolve(err)
        })
}

export const getTopicsAction = () => (dispatch) => {
    return getTopics()
        .then(res => {
            dispatch(setTopics(res))
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.resolve(err)
        })
}

export const postContactAppeal = (res) => {
    return axios.post(api.contactAppeal, res).then((res) => res);
}
