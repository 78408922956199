import logo from "assets/images/common/logo.png";
import MenuComponent from "../menu/menu.component";
import './header.component.scss';
import {useEffect, useRef} from "react";
import {NavLink, useLocation} from 'react-router-dom';
import paths from "../../../core/paths";
import {useState} from "react";
import classNames from "classnames";

const HeaderComponent = () => {
    const ref = useRef();
    const [menu, setMenu] = useState(false);
    const currentLocation = useLocation().pathname;
    const currentRoute = currentLocation.includes(paths.products + "/");
    const isSticky = (e) => {
        const header = ref.current;
        const scrollTop = document.body.scrollTop;
        scrollTop >= 50 ? header?.classList.add('header--sticky') : header?.classList.remove('header--sticky');
    };

    const toggleClass = classNames({
        'header__toggle d-block d-lg-none': true,
        'header__toggle--open': menu
    });

    const menuClass = classNames({
        'header__menu  d-lg-block': true,
        'd-none': !menu
    });

    useEffect(() => {

        document.body.addEventListener('scroll', isSticky);
        return () => {
            document.body.removeEventListener('scroll', isSticky);
        };
    }, []);
    useEffect(() => {
        setMenu(false);
    }, [currentLocation]);


    return (
        <header className={currentRoute ? 'header header--green' : 'header'} ref={ref}>
            <div className="container">
                <div className="row align-center">
                    <div className="col-4 col-md-3">
                        <div className="header__logo">
                            <NavLink to={paths.home}>
                                <img src={logo}/>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-8 col-md-9">
                         <div className={menuClass}>
                            <MenuComponent/>
                        </div>

                        <div className={toggleClass} onClick={() => setMenu(!menu)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderComponent;
