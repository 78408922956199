import Btn from "../../../../components/shared/button/btn";
import './home-lead.component.scss';
import {useSelector} from "react-redux";
import paths from "../../../../core/paths";
import {useNavigate} from "react-router-dom";

const HomeLeadComponent = () => {
    const content = useSelector((state) => state.main.homeContent);
    const navigate = useNavigate();

    return (
        <section id="lead" className="lead">
            <div className="container">
                <div className="row align-center">
                    <div className="col-md-6">
                        <h1 className="lead__heading">
                            {content?.title}
                        </h1>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-12">
                                <p className="lead__text" dangerouslySetInnerHTML={{__html: content?.description}}>
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="lead__buttons">
                                    <Btn onClick={() => navigate(paths.products)}>
                                        Məhsullar
                                    </Btn>
                                    <Btn line down onClick={() => navigate(paths.about)}>
                                        Haqqımızda
                                    </Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeLeadComponent;
