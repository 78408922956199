import "./product-details-card.component.scss";

const ProductDetailsCard = ({image, title, amount}) => {
    return (
        <div className="col-lg-4">
            <div className="product-details-card">
                <div className="product-details-card__image">
                    <img src={image}/>
                </div>
                <div className="product-details-card__title">
                    {title}
                </div>
                <div className="product-details-card__amount">
                    {amount}
                </div>
            </div>
        </div>
    );
};

export default ProductDetailsCard;
