import "./contact-form.component.scss";
import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import Btn from "../../../../components/shared/button/btn";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {getTopicsAction, postContactAppeal} from "../../modules/contact.actions";
import ContactAppealModel from "../../modules/contact-appeal.model";
import {toast} from "react-toastify";

const ContactFormComponent = () => {
    const content = useSelector((state) => state.main.contactContent);
    const topics = useSelector((state) => state.contact.topics);
    const {register, handleSubmit, resetField, formState: {errors}} = useForm();

    const section = {
        desc: content?.title,
        text: content?.description
    }
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTopicsAction());
    }, []);

    const sendAppeal = (data) => {
        let appealResult = new ContactAppealModel({...data});

        postContactAppeal(appealResult).then(res => {
            if (res) {
                resetField('email');
                resetField('phone');
                resetField('message');
                resetField('typeId');
            }
        });


    }

    return (
        <section className="contact-form" id="contact-form">
            <div className="container">
                <div className="row align-center">
                    <div className="col-lg-5">
                        <SectionHeading {...section} primary/>
                    </div>
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-lg-6 mb-35">

                            </div>
                            <div className="col-lg-6 mb-35">
                                <label className="label">
                                    E-poçt
                                </label>
                                <input type="text"
                                       className={errors.email ? 'input error' : 'input'} {...register("email", {required: true})}/>
                            </div>
                            <div className="col-lg-6 mb-35">
                                <label className="label">
                                    Əlaqə nömrəsi
                                </label>
                                <input type="text"
                                       className={errors.phone ? 'input error' : 'input'} {...register("phone", {required: true})}/>
                            </div>
                            <div className="col-lg-6 mb-35">
                                <label className="label">
                                    Mövzu
                                </label>
                                <select
                                    className={errors.typeId ? 'input error' : 'input'} {...register("typeId", {required: true})}>
                                    <option value="">Mövzu</option>
                                    {topics.length > 0 && topics.map((i, index) => (
                                        <option value={i.id} key={index}>{i.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-12 mb-35">
                                <label className="label">
                                    Mesajınız
                                </label>
                                <input type="text"
                                       className={errors.message ? 'input error' : 'input'} {...register("message", {required: true})}/>
                            </div>
                            <div className="col-12">
                                <Btn full onClick={handleSubmit((data) => {
                                    sendAppeal(data);
                                })}>Submit</Btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactFormComponent;
