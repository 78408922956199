import axios from "axios";
import api from "../../../core/api";
import PartnersModel from "./partners.model";


export const getPartners = () => {
    return axios.get(api.partners).then((res) => {
        let data = res.data.partners.items;
        if (data) {
            return data.map(i=>new PartnersModel(i));
        }
    })
}
