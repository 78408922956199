import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import {useSelector} from "react-redux";

const AboutLeadComponent = ({title,description,heading}) => {
    const content = useSelector((state) => state.main.homePhotoContent);

    const section = {
        title: "Biz kimik?",
        desc: heading,
        text: description
    }
    return (
        <section className="about-lead mb-50">
            <div className="container">
                <div className="row align-center">
                    <div className="col-md-6">
                        <h1 className="lead__heading lead__heading--sm">
                            {title}
                        </h1>
                    </div>
                    <div className="col-md-6">
                        <SectionHeading {...section}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutLeadComponent;
