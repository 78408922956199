import Btn from "../../../../components/shared/button/btn";
import "./partners-item-detail.component.scss";

const PartnersItemDetailComponent = ({id, logo, name, type, desc,url,closeModal}) => {
    return (
        <div className="partners-item-detail">
            <div className="partners-item-detail__header">
                <img src={logo} alt="Partner logo" className="partners-item-detail__logo"/>
                <div className="partners-item-detail__title">
                    <div className="partners-item-detail__name">
                        {name}
                    </div>
                    <div className="partners-item__type partners-item-detail__type">
                        {type}
                    </div>
                </div>
            </div>
            <div className="partners-item-detail__body"  dangerouslySetInnerHTML={{__html: desc}}>
            </div>
            <div className="partners-item-detail__footer">
                <div className="row">
                    <div className="col-6">
                        <Btn full link={url} blank>
                            Sayta keçid edin
                        </Btn>
                    </div>
                    <div className="col-6">
                        <Btn line full noIcon onClick={closeModal}>
                            Bağla
                        </Btn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnersItemDetailComponent;
