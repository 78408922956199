import Btn from "../../../../components/shared/button/btn";
import "./products-item.component.scss";
import {useNavigate} from "react-router-dom";
import paths from "../../../../core/paths";

const ProductsItemComponent = ({id, name, desc, period, amount, percent, image}) => {
    const navigate = useNavigate();

    return (
        <li className="products-item" style={{'backgroundImage': 'url(' + image + ')'}}>
            <div className="row">
                <div className="col-lg-7">
                    <div className="products-item__name">
                        {name}
                    </div>
                    <div className="products-item__desc"  dangerouslySetInnerHTML={{__html: desc}}>
                    </div>
                    <ul className="products-item__counts">
                        <li className="products-item__counts-item">
                            <div className="products-item__label">
                                Müddət (Ay)
                            </div>
                            <div className="products-item__number">
                                {period}
                            </div>
                        </li>
                        <li className="products-item__counts-item">
                            <div className="products-item__label">
                                Məbləğ (AZN)
                            </div>
                            <div className="products-item__number">
                                {amount}
                            </div>
                        </li>
                        <li className="products-item__counts-item d-none d-lg-block">
                            <div className="products-item__label">
                                Faiz dərəcəsi (%)
                            </div>
                            <div className="products-item__number">
                                {percent}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-12">
                    <div className="products-item__actions">
                        <Btn onClick={() => navigate(paths.products + '/' + id)}>Ətraflı</Btn>
                        {/*<Btn line onClick={() => navigate(paths.products + '/' + id)}>*/}
                        {/*    <strong>*/}
                        {/*        {name}*/}
                        {/*    </strong>*/}
                        {/*    &nbsp;*/}
                        {/*    haqqında*/}
                        {/*</Btn>*/}
                    </div>

                </div>
            </div>
        </li>
    );
};

export default ProductsItemComponent;
