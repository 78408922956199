import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    targets: [],
    strategies: []
}

export const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {
        setTargets: (state, payload) => {
            state.targets = payload.payload;
        },
        setStrategies: (state, payload) => {
            state.strategies = payload.payload;
        },
    },
})

export const {setTargets, setStrategies} = aboutSlice.actions

export default aboutSlice.reducer
