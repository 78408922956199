import AboutLeadComponent from "./components/about-lead/about-lead.component";
import AboutPhotoComponent from "./components/about-photo/about-photo.component";
import AboutPurposeComponent from "./components/about-purpose/about-purpose.component";
import AboutStrategyComponent from "./components/about-strategy/about-strategy.component";
import {useSelector} from "react-redux";

const AboutComponent = () => {
    const content = useSelector((state) => state.main.whoWeAreMediaContent);
    return (
        <div className="about">
            <AboutLeadComponent {...content}/>
            <AboutPhotoComponent photo={content?.url}/>
            <AboutPurposeComponent/>
            <AboutStrategyComponent/>
        </div>
    );
};

export default AboutComponent;
