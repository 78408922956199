import axios from "axios";
import api from "../../../core/api";
import ProductsModel from "./products.model";


export const getProducts = () => {
    return axios.get(api.products).then((res) => {
        let data = res.data?.product?.items;
        if (data) {
            return data.map(i=>new ProductsModel(i));
        }
    })
}
