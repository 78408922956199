import "./footer-left.component.scss";
import logo from "assets/images/common/logo.png";
import Fb from 'assets/images/icons/fb.svg';
import Ig from 'assets/images/icons/ig.svg';
import Yt from 'assets/images/icons/yt.svg';
import In from 'assets/images/icons/in.svg';
import Tw from 'assets/images/icons/tw.svg';

const FooterLeftComponent = () => {
    // TODO: social links

    const socials = [
        {
            url: "https://facebook.com",
            icon: Fb
        }, {
            url: "https://twitter.com",
            icon: Tw
        }, {
            url: "https://instagram.com",
            icon: Ig
        }, {
            url: "https://linkedin.com",
            icon: In
        }, {
            url: "https://youtube.com",
            icon: Yt
        },
    ]

    return (
        <div className="footer-left">
            <div className="row">
                <div className="col-12">
                    <div className="footer-left__logo">
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="footer-left__desc">
                        Kreditin doğru ünvanı
                    </div>
                </div>
                <div className="col-12">
                    <ul className="footer-left__socials">
                        {socials.map((item,index) => (
                            <li className='footer-left__socials-item' key={index}>
                                <a href={item.url} className='footer-left__socials-link' target='_blank'>
                                    <img src={item.icon} alt="social icon"/>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FooterLeftComponent;
