export default class ContactModel {
    constructor(item) {
        this._setLocation(item);
        this._setPhone(item);
        this._setEmail(item);
    }

    _setLocation({location}) {
        this.location = location;
    }

    _setPhone({email}) {
        this.email = email;
    }

    _setEmail({phone}) {
        this.phone = phone;
    }

}
