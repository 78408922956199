import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import "./home-products.component.scss";
import {useSelector} from "react-redux";

const HomeProductsComponent = () => {
    const content = useSelector((state) => state.main.productsContent);

    const section = {
        title: 'Məhsullar',
        desc: content?.title
    }
    return (
        <section className="home-products mt-80 mb-40">
            <div className="container">
                <div className="row align-center">
                    <div className="col-lg-4">
                        <SectionHeading {...section}/>
                    </div>
                    <div className="col-lg-8">
                        <div className="home-products__text"  dangerouslySetInnerHTML={{__html: content?.description}}>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeProductsComponent;
