import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    homeContent: null,
    productsContent: null,
    loanContent: null,
    productInnerContent: null,
    whoAreWeContent: null,
    ourAimContent: null,
    strategyContent: null,
    partnerContent: null,
    discoverContent: null,
    contactContent: null,
    homeVideoContent: null,
    homePhotoContent: null,
    whoWeAreMediaContent: null,
    contactPhoto1: null,
    contactPhoto2: null,
    contactPhoto3: null,
    contactPhoto4: null,
    loader: false,
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setContents: (state, payload) => {
            state.contents = payload.payload;
            payload.payload.forEach(i => {
                switch (Number(i.typeId)) {
                    case 1:
                        state.homeContent = i;
                        break;
                    case 2:
                        state.productsContent = i;
                        break;
                    case 3:
                        state.loanContent = i;
                        break;
                    case 4:
                        state.productInnerContent = i;
                        break;
                    case 5:
                        state.whoAreWeContent = i;
                        break;
                    case 6:
                        state.ourAimContent = i;
                        break;
                    case 7:
                        state.strategyContent = i;
                        break;
                    case 8:
                        state.partnerContent = i;
                        break;
                    case 9:
                        state.discoverContent = i;
                        break;
                    case 10:
                        state.contactContent = i;
                        break;

                }
            });
        },
        setMediaContents: (state, payload) => {
            payload.payload.forEach(i => {
                switch (Number(i.typeId)) {
                    case 1:
                        state.homeVideoContent = i;
                        break;
                    case 2:
                        state.homePhotoContent = i;
                        break;
                    case 3:
                        state.whoWeAreMediaContent = i;
                        break;
                    case 4:
                        state.contactPhoto1 = i;
                        break;
                    case 5:
                        state.contactPhoto2 = i;
                        break;
                    case 6:
                        state.contactPhoto3 = i;
                        break;
                    case 7:
                        state.contactPhoto4 = i;
                        break;

                }
            });
        },
        setLoader: (state, payload) => {
            state.loader = payload.payload;
        }
    },
})

export const {setContents, setMediaContents, setLoader} = mainSlice.actions

export default mainSlice.reducer
