import "./home-loan-input.component.scss";
import {Slider} from 'antd';

const HomeLoanInputComponent = ({label,min, max, measure, defaultNo, onChange,steps=1}) => {
    return (
        <div className="home-loan-input">
            <label className="home-loan-input__label">
                {label}
            </label>
            <div className="home-loan-input__content">
                <input type="text" className="input" value={defaultNo}
                       onChange={(e) => onChange(e.target.value)}/>
                <span className="home-loan-input__measure">
                {measure}
            </span>
                <Slider min={min}
                        max={max}
                        onChange={onChange}
                        value={defaultNo}
                        step={steps}
                />
            </div>
        </div>
    );
};

export default HomeLoanInputComponent;
