import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import HomeLoanCalcComponent from "../home-loan-calc/home-loan-calc.component";
import {useSelector} from "react-redux";

const HomeLoanComponent = () => {
    const content = useSelector((state) => state.main.loanContent);

    const section = {
        title: "Kreditə müraciət edin",
        desc: content?.title,
        text: content?.description
    }
    return (
        <section className="home-calc mb-100">
            <div className="container">
                <div className="section-bg">
                    <div className="row align-center">
                        <div className="col-lg-4">
                            <SectionHeading {...section}/>
                        </div>
                        <div className="col-lg-8">
                            <HomeLoanCalcComponent/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeLoanComponent;
