import PartnersLeadComponent from "./components/partners-lead/partners-lead.component";
import PartnersListComponent from "./components/partners-list/partners-list.component";

const PartnersComponent = () => {
    return (
        <div className="partners">
            <PartnersLeadComponent/>
            <PartnersListComponent/>
        </div>
    );
};

export default PartnersComponent;
