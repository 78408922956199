import axios from "axios";
import api from "../../../core/api";
import TargetModel from "./target.model";
import StrategyModel from "./strategy.model";


export const getTargets = () => {
    return axios.get(api.targets).then((res) => {
        let data = res.data?.targets?.items;
        if (data) {
            return data.map(i=>new TargetModel(i));
        }
    });
}


export const getStrategies = () => {
    return axios.get(api.strategies).then((res) => {
        let data = res.data?.strategies?.items;
        if (data) {
            return data.map(i=>new StrategyModel(i));
        }
    });
}

