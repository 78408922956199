import "./about-purpose-content.component.scss";

const AboutPurposeContentComponent = ({image, title, text}) => {
    return (
        <div className="about-purpose-content">
            <div className="about-purpose-content__image">
                <img src={image} alt="image"/>
            </div>
            <div className="about-purpose-content__right">
                <div className="about-purpose-content__title">
                    {title}
                </div>
                <div className="about-purpose-content__text"  dangerouslySetInnerHTML={{__html: text}}>
                </div>
            </div>
        </div>
    );
};

export default AboutPurposeContentComponent;
