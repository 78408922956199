import "./product-details-lead.component.scss";

const ProductDetailsLeadComponent = ({title,image}) => {
    return (
        <div className="product-details-lead">
            <div className="container">
                <div className="row align-center">
                    <div className="col-lg-6">
                        <div className="lead__heading lead__heading--sm">
                            {title}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="product-details-lead__photo">
                            <img src={image}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetailsLeadComponent;
