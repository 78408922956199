import axios from "axios";
import api from "../../../core/api";
import ContactModel from "./contact.model";
import TopicModel from "./topic.model";


export const getContacts = () => {
    return axios.get(api.contact).then((res) => {
        let data = res.data.contacts[0];
        if (data) {
            return new ContactModel(data);
        }
    })
}

export const getTopics = () => {
    return axios.get(api.topics).then((res) => {
        let data = res.data.ContactFormTopic?.items;
        if (data) {
            return data.map(i=>new TopicModel(i));
        }
    })
}
