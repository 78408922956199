import image from "assets/images/common/pikachu.jpg";
import PartnersItemComponent from "../partners-item/partners-item.component";
import {useState} from "react";
import Modal from "../../../../components/shared/modal/modal";
import PartnersItemDetailComponent from "../partners-item-detail/partners-item-detail.component";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getPartnersAction} from "../../modules/partners.action";

const PartnersListComponent = () => {
    const dispatch = useDispatch();
    const partners = useSelector((state) => state.partners.partners);

    useEffect(() => {
        dispatch(getPartnersAction());
    }, []);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const showDetails = (e) => {
        const object = partners.find(obj => obj.id === e);
        setModalContent(object);
        setIsModalOpen(true);
    }
    return (

        <section className="partners-list mt-50">
            <div className="container">
                <div className="row align-center">
                    {partners.length>0&&partners.map((item, index) => (
                        <PartnersItemComponent key={index} {...item} detailsModal={showDetails}/>
                    ))}
                </div>
            </div>
            {isModalOpen && <Modal onClose={closeModal} w="660">
                <PartnersItemDetailComponent {...modalContent} closeModal={closeModal}/>
            </Modal>}
        </section>
    );
};

export default PartnersListComponent;
