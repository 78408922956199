import axios from "axios";
import {store} from "./store";
import {setLoader} from "../modules/main.store";
import {toast} from "react-toastify";
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem(`admin.${process.env.REACT_APP_NAME}`);

axios.interceptors.request.use(function (config) {
    store.dispatch(setLoader(true));
    return config;
}, function (error) {
    store.dispatch(setLoader(true));
    return Promise.reject(error);
});

axios.interceptors.response.use(
    (response) => {
        let method = response.config.method.toUpperCase();
        if (method === 'POST') {
            toast.success("Müraciət göndərildi");
        }
        if(response.data) {
            store.dispatch(setLoader(false));
        }
        return response;
    },
    (error) => {
        let errMessage = "Xəta baş verdi";

        const {
            response: {status},
        } = error;

        if (status === 401) {
            errMessage = "Sessiya müddəti bitmişdir";
            localStorage.removeItem(`admin.${process.env.REACT_APP_NAME}`);
            // router.push({name: "login"});
        } else if (status === 404) {
            errMessage = 'Məlumat tapılmadı';
            // router.push({name: 'public'}).catch(() => {
            // });
        } else {
            let err = error.response.data;
            if (err.length) {
                errMessage = err;
            } else if (err.errors) {
                let keys = Object.keys(err.errors);
                errMessage = err.errors[keys[0]][0];
            }
        }
        toast.error(errMessage);
        store.dispatch(setLoader(false));

    }
);

