import "./about-strategy.component.scss";
import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import image from "assets/images/icons/placeholder.svg";
import AboutStrategyCardComponent from "../about-strategy-card/about-strategy-card.component";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getStrategiesAction} from "../../modules/about.actions";

const AboutStrategyComponent = () => {
    const content = useSelector((state) => state.main.strategyContent);
    const dispatch = useDispatch();
    const strategies = useSelector((state) => state.about.strategies);

    useEffect(() => {
        dispatch(getStrategiesAction());
    }, []);

    const section = {
        title: "Strategiyamız",
        desc: content?.title
    }

    return (
        <div className="about-strategy mb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <SectionHeading {...section}/>
                    </div>
                    <div className="col-12 mt-20">
                        <div className="row justify-end">
                            {strategies.length>0&&strategies.map((item, index) => (
                                <AboutStrategyCardComponent key={index} {...item}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutStrategyComponent;
