import { getPartners} from "./partners.services";
import { setPartners} from "./partners.store";

export const getPartnersAction = ()=>(dispatch)=>{
    return getPartners()
        .then(res=>{
            dispatch(setPartners(res))
            return Promise.resolve(res)
        })
        .catch(err=>{
            return Promise.resolve(err)
        })
}
