import "./about-purpose.component.scss";
import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import AboutPurposeContentComponent from "../about-purpose-content/about-purpose-content.component";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getTargetsAction} from "../../modules/about.actions";

const AboutPurposeComponent = () => {
    const content = useSelector((state) => state.main.ourAimContent);
    const dispatch = useDispatch();
    const targets = useSelector((state) => state.about.targets);

    useEffect(() => {
        dispatch(getTargetsAction());
    }, []);

    const section = {
        title: "Məqsədimiz",
        desc: content?.title,
        text: content?.description
    }

    return (<section className="about-purpose mb-100">
        <div className="container">
            <div className="section-bg">
                <div className="row align-center">
                    <div className="col-lg-5">
                        <SectionHeading {...section}/>
                    </div>
                    <div className="col-lg-7">
                        {targets.length>0&&targets.map((item, index) => (
                            <AboutPurposeContentComponent key={index} {...item}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </section>);
};

export default AboutPurposeComponent;
