export default class ContactAppealModel {
    constructor(item) {
        this._setPhone(item);
        this._setEmail(item);
        this._setMessage(item);
        this._setTopicId(item);

    }

    _setPhone({phone}) {
        this.phone = phone;
    }

    _setEmail({email}) {
        this.email = email;
    }

    _setMessage({message}) {
        this.message = message;
    }

    _setTopicId({typeId}) {
        this.contactFormTopicId = Number(typeId);
    }


}
