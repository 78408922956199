import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import {useSelector} from "react-redux";

const ProductsLeadComponent = () => {
    const content = useSelector((state) => state.main.productInnerContent);

    const section = {
        desc: content?.heading,
        text: content?.description
    }
    return (
        <section className="products-lead mb-50">
            <div className="container">
                <div className="row align-center">
                    <div className="col-md-6">
                        <h1 className="lead__heading lead__heading--sm">{content?.title}</h1>
                    </div>
                    <div className="col-md-6">
                        <SectionHeading {...section}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductsLeadComponent;
