import "./footer-bottom.component.scss";

const FooterBottomComponent = () => {
    const today = new Date().getFullYear();
    return (
        <div className="footer-bottom">
            <div className="row">
                <div className="col-md-6">
                    <div className="footer-bottom__copyright">
                        Müəllif hüququ &#169; {today} FNC
                    </div>
                </div>
                <div className="col-md-6 d-none d-lg-block">
                    <ul className="footer-bottom__rights">
                        <li className="footer-bottom__rights-item">
                            Bütün hüquqlar qorunur
                        </li>
                        |
                        <li className="footer-bottom__rights-item">
                            <a href="" onClick={e=>e.preventDefault()}>
                                Şərtlər və Qaydalar
                            </a>
                        </li> |
                        <li className="footer-bottom__rights-item">
                            <a href="" onClick={e=>e.preventDefault()}>
                                Gizlilik Siyasəti
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FooterBottomComponent;
