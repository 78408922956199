export default class TargetModel {
    constructor(item) {
        this._setId(item);
        this._setImage(item);
        this._setTitle(item);
        this._setText(item);
    }

    _setId({id}) {
        this.id = id;
    }

    _setImage({fileUrl}) {
        this.image = fileUrl;
    }

    _setTitle({title}) {
        this.title = title;
    }

    _setText({description}) {
        this.text = description;
    }

}
