import {setContents, setLoader, setMediaContents} from "./main.store";
import {getContents, getMediaContents} from "./main.services";

export const getContentsAction = ()=>(dispatch)=>{
    return getContents()
        .then(res=>{
            dispatch(setContents(res))
            return Promise.resolve(res)
        })
        .catch(err=>{
            return Promise.resolve(err)
        })
}


export const getMediaContentsAction = ()=>(dispatch)=>{
    return getMediaContents()
        .then(res=>{
            dispatch(setMediaContents(res))
            return Promise.resolve(res)
        })
        .catch(err=>{
            return Promise.resolve(err)
        })
}

export const changeLoader = (payload)=>(dispatch)=>{
    console.log(payload);
    dispatch(setLoader(payload))
}
