import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import Btn from "../../../../components/shared/button/btn";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import paths from "../../../../core/paths";

const PartnersLeadComponent = () => {
    const content = useSelector((state) => state.main.partnerContent);

    const section = {
        desc: content?.heading,
        text: content?.description
    }
    return (
        <section className="partners-lead">
            <div className="container">
                <div className="row align-center">
                    <div className="col-md-6">
                        <h1 className="lead__heading lead__heading--sm">
                            {content?.title}
                        </h1>
                    </div>
                    <div className="col-md-6">
                        <SectionHeading {...section}>
                            <div className="mt-30">
                                <NavLink to={paths.contact} className="btn btn--main" style={{width: 'fit-content'}}>
                                    Partnyorumuz olun!
                                </NavLink>

                            </div>
                        </SectionHeading>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnersLeadComponent;
