import HomeLeadComponent from "./components/home-lead/home-lead.component";
import HomeAboutComponent from "./components/home-about/home-about.component";
import HomeVideoComponent from "./components/home-video/home-video.component";
import "./home-page.component.scss";
import HomeProductsComponent from "./components/home-products/home-products.component";
import HomeLoanComponent from "./components/home-loan/home-loan.component";

const HomePageComponent = () => {
    return (
        <>
            <HomeLeadComponent/>
            <div className="container">
                <div className="home-bg">
                    <HomeVideoComponent/>
                    <HomeAboutComponent/>
                </div>
            </div>
            <HomeProductsComponent/>
            <HomeLoanComponent/>
        </>
    );
};

export default HomePageComponent;
