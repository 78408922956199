import image from "assets/images/icons/player.svg";
import image2 from "assets/images/icons/pause.svg";
import "./home-video.component.scss";
import {useSelector} from "react-redux";
import {useRef, useState} from "react";

const HomeVideoComponent = () => {
    const content = useSelector((state) => state.main.homeVideoContent);
    const [isPlaying,setIsPlaying] = useState(false);

    const playVideo = () => {
        if (!isPlaying) {
            videoRef.current.play();
            setIsPlaying(true);
        } else  {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    }
    const videoRef = useRef(null);
    return (
        <section id="home-video" className="home-video mb-80">
            <div className="row justify-center">
                <div className="col-lg-10 col-11">
                    <div className="home-video__cover">
                        <video src={content?.url} className='home-video__media' ref={videoRef} autoPlay={true} muted/>
                        {/*<img src={content?.url} className='home-video__media'/>*/}
                        {/*<img src={isPlaying?image2:image} className="home-video__player" onClick={playVideo}/>*/}
                    </div>
                    <div className="home-video__text" dangerouslySetInnerHTML={{__html: content?.description}}>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeVideoComponent;
