import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import "./contact-discover.component.scss";
import ContactImagesComponent from "../contact-images/contact-images.component";
import image from "assets/images/common/pikachu.jpg";
import {useSelector} from "react-redux";

const ContactDiscoverComponent = ({location,email,phone}) => {
    const content = useSelector((state) => state.main.discoverContent);

    const section = {
        desc: content?.title,
        text: content?.description
    }
    const contacts = [
        {
            title: 'Ünvan',
            text: location
        }, {
            title: 'E-mail',
            text: email
        }, {
            title: 'Telefon',
            text: phone
        },
    ];
    return (
        <section className="contact-discover">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="contact-discover__title">
                            <SectionHeading {...section} />
                        </div>
                        <ul className="contact-discover__list">
                            {contacts.map((item, index) => (
                                <li className="contact-discover__item" key={index}>
                                    <div className="contact-discover__item-heading">
                                        {item.title}
                                    </div>
                                    <div className="contact-discover__item-text">
                                        {item.text}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-7">
                        <ContactImagesComponent/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactDiscoverComponent;
