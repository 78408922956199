import ProductsItemComponent from "../products-item/products-item.component";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getProductsAction} from "../../modules/products.actions";

const ProductsListComponent = () => {
    const dispatch = useDispatch();

    const products = useSelector((state) => state.products.products);
    useEffect(() => {
        dispatch(getProductsAction());
    }, [])
    return (
        <section className="products-list">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="products-list__content">
                            {products.length > 0 ? products.map((item,index) =>
                                <ProductsItemComponent {...item} key={index}/>
                            ): null}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductsListComponent;
