export default class ProductsModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
        this._setDesc(item);
        this._setPeriod(item);
        this._setAmount(item);
        this._setPercent(item);
        this._setImage(item);
        this._setCommission(item);
        this._setHeading(item);
        this._setInitialPayment(item);
    }

    _setId({id}) {
        this.id = id;
    }

    _setName({title}) {
        this.name = title;
    }

    _setDesc({description}) {
        this.desc = description;
    }

    _setPeriod({loanInterval}) {
        this.period = loanInterval;
    }

    _setAmount({amount}) {
        this.amount = amount;
    }

    _setPercent({percentageRate}) {
        this.percent = percentageRate;
    }

    _setImage({fileUrl}) {
        this.image = fileUrl;
    }

    _setCommission({commission}) {
        this.commission = commission;
    }

    _setInitialPayment({initialPayment}) {
        this.initialPayment = initialPayment;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

}
