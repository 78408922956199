import './footer.component.scss';
import FooterBottomComponent from "./components/footer-bottom/footer-bottom.component";
import FooterLeftComponent from "./components/footer-left/footer-left.component";
import FooterRightComponent from "./components/footer-right/footer-right.component";

const FooterComponent = () => {


    return (
        <header className="footer">
            <div className="container">
                <div className="col-12">
                    <div className="row mb-90">
                        <div className="col-lg-6">
                            <FooterLeftComponent/>
                        </div>
                        <div className="col-lg-6">
                            <FooterRightComponent/>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <FooterBottomComponent/>
                </div>
            </div>
        </header>
    );
};

export default FooterComponent;
