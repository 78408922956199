import {getStrategies, getTargets} from "./about.services";
import {setStrategies, setTargets} from "./about.store";

export const getTargetsAction = () => (dispatch) => {
    return getTargets()
        .then(res => {
            dispatch(setTargets(res))
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.resolve(err)
        })
}

export const getStrategiesAction = () => (dispatch) => {
    return getStrategies()
        .then(res => {
            dispatch(setStrategies(res))
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.resolve(err)
        })
}
