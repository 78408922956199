import SectionHeading from "../../../../components/shared/section-heading/section-heading";
import "./home-about.component.scss";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import paths from "../../../../core/paths";

const HomeAboutComponent = () => {
    const content = useSelector((state) => state.main.homePhotoContent);

    const section = {
        title: "HAQQIMIZDA",
        desc: content?.title,
        text: content?.description
    }
    return (
        <section id="home-about" className="home-about">
            <div className="row justify-center">
                <div className="col-lg-10 col-11">
                    <div className="row">
                        <div className="col-lg-6">
                            <SectionHeading {...section}/>
                            <NavLink to={paths.about}  className="home-about__more" end>
                                Ətraflı
                            </NavLink>
                        </div>
                        <div className="col-lg-6">
                            <div className="home-about__image">
                                <img src={content?.url}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeAboutComponent;
