import ProductDetailsLeadComponent from "./components/product-details-lead/product-details-lead.component";
import HomeLoanComponent from "../home-page/components/home-loan/home-loan.component";
import ProductDetailsAboutComponent from "./components/product-details-about/product-details-about.component";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {getProductsAction} from "../products/modules/products.actions";

const ProductDetailsComponent = () => {
    const products = useSelector((state) => state.products.products);
    const {id} = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProductsAction());
    }, [])
    const product = products.length?products.filter(i => i.id == id)[0]:null;

    return (
        <div className="product-details">
            <ProductDetailsLeadComponent title={product?.name} image={product?.image}/>
            <ProductDetailsAboutComponent {...product}/>
            <HomeLoanComponent/>
        </div>
    );
};

export default ProductDetailsComponent;
