import './loader.component.scss';


const LoaderComponent = () => {

    return (
        <div className='loader'>
            <div className="lds-circle">
                <div></div>
            </div>
        </div>
    );
};

export default LoaderComponent;
