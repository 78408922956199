import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import HomePageComponent from "../views/home-page/home-page.component";
import paths from "./paths";
import App from "../App";
import PartnersComponent from "../views/partners/partners.component";
import AboutComponent from "../views/about/about.component";
import ContactComponent from "../views/contact/contact.component";
import ProductsComponent from "../views/products/products.component";
import ProductDetailsComponent from "../views/product-details/product-details.component";

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        exact: true,
        children: [
            {
                path: paths.home,
                element: <HomePageComponent/>,
            },
            {
                path: paths.about,
                element: <AboutComponent/>,
            },
            {
                path: paths.partners,
                element: <PartnersComponent/>,
            },
            {
                path: paths.contact,
                element: <ContactComponent/>,
            },
            {
                path: paths.products,
                element: <ProductsComponent/>,
            },
            {
                path: paths.products + "/:id",
                element: <ProductDetailsComponent/>,
            },
            {
                path: "*",
                element: <HomePageComponent/>,


            },
        ]
    }

]);

export default router;
