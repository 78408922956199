import "./section-heading.scss"

const SectionHeading = ({children,title, desc, text,primary}) => {
    return (
        <div className="section-heading">
            {(title && <div className="section-heading__title">
                {title}
            </div>)}
            <div className={primary?"section-heading__desc section-heading__desc--green":"section-heading__desc"}>
                {desc}
            </div>
            {(text && <div className="section-heading__text"  dangerouslySetInnerHTML={{__html: text}}>
            </div>)}
            {children}
        </div>
    );
};

export default SectionHeading;
