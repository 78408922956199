import "./modal.scss"
import React, {useRef} from "react";

const Modal = ({children, onClose, w}) => {
    const wrapperRef = useRef(null);
    const handleClick = (e) => {
        if (!wrapperRef.current.contains(e.target)) {
            onClose();
        }
    };
    return (
        <div className="modal" onClick={handleClick}>
            <div className="modal__content" ref={wrapperRef} style={{"width": w + "px"}}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
