import {getProducts} from "./products.services";
import {setProducts} from "./products.store";

export const getProductsAction = ()=>(dispatch)=>{
    return getProducts()
        .then(res=>{
            dispatch(setProducts(res))
            return Promise.resolve(res)
        })
        .catch(err=>{
            return Promise.resolve(err)
        })
}
