import "./about-photo.component.scss";

const AboutPhotoComponent = ({photo}) => {

    return (
        <section className="about-photo">
            <div className="container">
                <div className="about-photo__content">
                    <div className="row justify-center">
                        <div className="col-lg-9">
                            <img src={photo} alt="about image" className="about-photo__image"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPhotoComponent;
