export default class PartnersModel {
    constructor(item) {
        this._setId(item);
        this._setLogo(item);
        this._setType(item);
        this._setDesc(item);
        this._setName(item);
        this._setUrl(item);
    }

    _setId({id}) {
        this.id = id;
    }

    _setLogo({fileUrl}) {
        this.logo = fileUrl;
    }

    _setType({partnerType}) {
        this.type = partnerType?.name;
    }

    _setDesc({description}) {
        this.desc = description;
    }

    _setName({name}) {
        this.name = name;
    }

    _setUrl({url}) {
        this.url = url;
    }


}
