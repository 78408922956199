export default {
    contact: 'contacts',
    contents: 'abouts',
    mediaContents: 'contents',
    products: 'products',
    partners: 'partners',
    targets: 'targets',
    strategies: 'strategys',
    appeal: 'loanApplication',
    topics: 'contactFormTopics',
    contactAppeal: 'contactForms'
}
